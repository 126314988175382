import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import qs from 'qs';
import { Spinner } from 'reactstrap';
import { Trans } from '@lingui/react';
import { Button } from 'bokamera-embedded-ui';

import request from '../utils/request';
import api from '../utils/api';

import _StripeCheckout from './StripeCheckout';
import { Booking } from '@/types';
import { getCountryCode, getErrorMessageFromApiError, readConfigurationProperty } from '@/utils/common';
import { WithStripe } from '@/hoc/withStripe';
import { useAppDispatch } from '@/hooks/hook';
import { setCompanyId } from '@/reducers/stripeSlice';

interface Props {
    bookingId: string;
}

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const Payment: React.FC<Props> = (props) => {
    const dispatch = useAppDispatch();
    const [checkout, setCheckout] = useState<any>();
    const [paymentProviderId, setPaymentProviderId] = useState<number | undefined>();
    const { bookingId } = props;
    const [error, setError] = useState<any>();
    const { push } = useHistory();
    const payWithPaysonV1 = paymentProviderId === 1;
    const payWithPaysonV2 = paymentProviderId === 2;
    const payWithBillmate = paymentProviderId === 3;
    const payWithStripe = paymentProviderId === 5;

    const StripeCheckout = WithStripe(_StripeCheckout);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const bookingResponse = await request(api.fetchBookings({ Id: bookingId }));

            const params = qs.parse(document.location.search, { ignoreQueryPrefix: true });
            const booking = bookingResponse.data.Results[0] as Booking;
            dispatch(setCompanyId(booking.Company.Id));

            let confirmationUrl;
            const email = booking.Customer ? booking.Customer.Email : null;
            const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

            if (queryParams.paymentConfirmationURL) {
                const search = qs.stringify({
                    bookingId,
                    email,
                });
                confirmationUrl = `${document.location.origin}/#/payment-success?${search}`;
            } else {
                const search = qs.stringify({
                    ...params,
                    bookingId,
                    email,
                });
                confirmationUrl = `${document.location.origin}/#/payment-success?${search}`;
            }

            const checkoutResponse = await request(
                api.createCheckout({
                    Id: booking.Id,
                    CompanyId: booking.Company.Id,
                    PaymentProviderId: booking.Company.PaymentProviderId,
                    CheckoutUrl: document.location.href,
                    ConfirmationUrl: confirmationUrl,
                    CountryCode: getCountryCode(readConfigurationProperty('language')),
                    Articles: []
                })
            );
            
            // Payson v1 doesn't work inside iframe
            if (payWithPaysonV1) {
                const redirectUrl = checkoutResponse.data.Snippet;
                if (window.top && inIframe()) {
                    window.top.location.href = redirectUrl;
                } else {
                    window.location.href = redirectUrl;
                }
            }

            setPaymentProviderId(booking.Company.PaymentProviderId);
            setCheckout(checkoutResponse.data);

            const paysonContainer = document.getElementById('paysonContainer');
            if (paysonContainer && payWithPaysonV2) {
                const scriptParentNode: Node | null = paysonContainer.parentNode;
                if (!!scriptParentNode) {
                    // How to rewrite it in correct way?
                    // @ts-ignore
                    const scriptNode = scriptParentNode.getElementsByTagName('script')[0];
                    const scriptNodeWithContent = document.createElement('script');
                    scriptNodeWithContent.src = scriptNode.src;
                    document.head.appendChild(scriptNodeWithContent);
                }
            }
            } catch (error: any) {
                setError(getErrorMessageFromApiError(error))
            }
            
        };
        
        fetchData();
    }, [bookingId]);

    return (
        <div className="Payment">
            {checkout ? (
                <>
                    {payWithPaysonV2 && (
                        <div
                            dangerouslySetInnerHTML={{ __html: checkout.Snippet }}
                            data-testid="paysonContainer"
                        />
                    )}
                    {payWithBillmate && (
                        <iframe
                            src={checkout.Url}
                            width="100%"
                            height="810px"
                            frameBorder="0"
                            data-testid="billmateContainer"
                            title="Billmate"
                        />
                    )}
                    {payWithStripe ? (
                        <StripeCheckout clientSecret={checkout.ClientSecret} />
                    ) : null}
                </>
            ) : error ? (
                <div className="container p-2 text-center">
                    <p><Trans id="payment.followingErrorHasOccured" values={{ bookingId }} /></p>
                    <p className="text-danger">{error}</p>
                    <Button
                        primary
                        onClick={() => {
                            push("/");
                            window.location.reload();
                        }}
                    >
                        <Trans id="reload" />
                    </Button>
                </div>
            ) : (
                <div className="Payment__loading">
                    <Spinner color="secondary" />
                </div>
            )}
        </div>
    );
};

interface PageProps extends RouteComponentProps<{ bookingId: string }> {}

const PaymentPage: React.FC<PageProps> = (props) => (
    <Payment bookingId={props.match.params.bookingId} />
);

export default PaymentPage;
