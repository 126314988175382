import { AxiosRequestConfig } from 'axios';
import { realmParamsMapping } from '@/utils/common';
import { ArticleToCreate, CountryCode, GetCompanyQuery } from '@/types';
import qs from 'qs';
import Payment from './paymentService';


export interface UserRatingData {
    CompanyId: string;
    BookingId: number;
    Identifier: string;
    RatingScore: number;
    Review?: {
        Title: string;
        Description: string;
        Author: string;
    };
}

export default {
    authenticate(params?: Object): AxiosRequestConfig {
        return {
            url: '/authenticate',
            method: 'POST',
            data: {
                provider: 'credentials',
                RememberMe: true,
                UseTokenCookie: true,
                ...params,
            },
        };
    },
    resetPassword(data: { Email: string, Realm: string }): AxiosRequestConfig {
        return {
            url: '/users/forgotpassword',
            method: 'POST',
            data,
        };
    },
    fetchAuth(params?: Object): AxiosRequestConfig {
        return {
            url: '/authenticate',
            method: 'GET',
        };
    },
    fetchRatings(params?: Object): AxiosRequestConfig {
        return {
            url: '/rating',
            method: 'GET',
            params: {
                ...params,
            },
        };
    },
    fetchBookings(params?: Object): AxiosRequestConfig {
        return {
            url: '/bookings',
            method: 'GET',
            params: {
                OrderByDesc: 'From',
                IncludeCustomFieldValues: true,
                IncludeBookedResourceTypes: true,
                IncludeServiceInformation: true,
                IncludeQuantityInformation: true,
                IncludeStatusInformation: true,
                IncludeCompanyInformation: true,
                IncludeCustomerInformation: true,
                ...params,
            },
        };
    },
    cancelBooking(bookingId: number): AxiosRequestConfig {
        return {
            url: `/bookings/${bookingId}`,
            method: 'DELETE',
        };
    },
    deleteByCode(
        id: string,
        params?: { UnBookedComments?: string; CancellationCode: string }
    ): AxiosRequestConfig {
        return {
            url: `/bookings/deletebycode/${id}`,
            method: 'DELETE',
            params: {
                ...params,
            },
        };
    },
    fetchUser(): AxiosRequestConfig {
        return {
            url: '/users',
            method: 'GET',
        };
    },
    getCompanySettings(params: GetCompanyQuery = {}): AxiosRequestConfig {
        return {
            url: '/companies',
            method: 'GET',
            params: {
                IncludeBookingSettings: true,
                ...params
            }
        };
    },
    getStripeAccount({ companyId }:{ companyId: string }): AxiosRequestConfig {
        return {
            url: `/payment/stripe/v1/account?${qs.stringify({ CompanyId: companyId })}`,
            method: 'GET',
            
        };
    },
    editUser(params: any): AxiosRequestConfig {
        return {
            url: `/users`,
            method: 'PUT',
            data: {
                UserProfile: {
                    // @ts-ignore
                    Realm: realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM],
                    UserId: params.Id,
                    ...params,
                    
                },
            },
        };
    },
    changePassword(data: any): AxiosRequestConfig {
        return {
            url: `/users/forgotpassword`,
            method: 'POST',
            data,
        };
    },
    logout(): AxiosRequestConfig {
        return {
            url: '/authenticate/logout',
            method: 'POST',
        };
    },
    addRating(data: UserRatingData): AxiosRequestConfig {
        return {
            url: '/rating',
            method: 'POST',
            params: { Active: true },
            data,
        };
    },
    updateRating(data: UserRatingData): AxiosRequestConfig {
        return {
            url: '/rating',
            method: 'PUT',
            params: { Active: true },
            data,
        };
    },
    createCheckout(checkoutData: {
        Id: number;
        PaymentOrigin?: number;
        CompanyId: string;
        CheckoutUrl: string;
        ConfirmationUrl: string;
        PaymentProviderId?: number;
        CountryCode: CountryCode;
        Articles: ArticleToCreate[]
    }): AxiosRequestConfig {
        let axiosPayload = new Payment().createCheckout(checkoutData);

        return axiosPayload;
    },
    deleteUser(
        params?: {
            UserName?: string;
            DeleteCustomerProfiles?: boolean;
            ForceDelete?: boolean;
            Realm?: string;
            Token?: string;
         }
    ): AxiosRequestConfig {
        return {
            url: `/users`,
            method: 'DELETE',
            params: {
                // @ts-ignore
                Realm: realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM],
                ForceDelete: false,
                ...params,
            },
        };
    },
};
