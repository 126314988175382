import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import bmApi from "@/services/bmApi";
import qs from 'qs';
import { decryptSymmetric, encryptionIVV, encryptionKey } from '@/utils/common';
import api from '@/utils/api';
import request from '@/utils/request';


export const STRIPE_AUTH_PATHNAME = '/stripe-auth';

interface Entity {
    accountId?: string;
    companyId?: string
}

interface InitialState {
    entity: Entity | null;
    isLoading: boolean;
    error: any;
}

export const initialState: InitialState = {
    entity: null,
    error: null,
    isLoading: false,
};

// actions
export const setCompanyId = createAction('/stripe/SET_COMPANY_ID', (companyId: string) => ({ payload: companyId }))

// Thunks
export const getAccount = createAsyncThunk(
    '/stripe/authenticate',
    async (companyId: string , thunkApi) => {
        try {
            const encryptedAccountId: any = await request(api.getStripeAccount({ companyId }))
              .then((res) => res.data.Results[0].StripeAccount);
            
              if (encryptionIVV && encryptionKey) {
                const accountId = await decryptSymmetric(
                  encryptedAccountId,
                  encryptionIVV,
                  encryptionKey
                );
                return thunkApi.fulfillWithValue(accountId as string);
              } else {
                throw new Error("Encryption keys not set");
              }
        } catch (error: any) {
            return thunkApi.rejectWithValue(error);
        }   
    }
)

// Slice

const stripeAuthSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(setCompanyId, (state, action) => {
            if(!state.entity) {
                state.entity = {}
            }
            
            state.entity.companyId = action.payload;
            
        });

        builder.addCase(getAccount.pending, (state, action) => {
            state.isLoading = true;
        });

        builder.addCase(getAccount.fulfilled, (state, action) => {
            if(!state.entity) {
                state.entity = {};
            }

            state.entity.accountId = action.payload;

            state.isLoading = false;
            state.error = false;
        });

        builder.addCase(getAccount.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    }
});


export default stripeAuthSlice;
